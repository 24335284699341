export default {
  // heading: 'Racing Sans One, cursive',
  // body: 'Open Sans, sans-serif',
  // monospace: 'Menlo, monospace',
  // display: 'Racing Sans One, cursive',

  heading: 'Open Sans Condensed, sans-serif',
  body: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Open Sans Condensed, sans-serif',
  googleFonts: ['Racing Sans One', 'Open Sans', 'Open Sans Condensed:300,700']
  // customFamilies: [''],
  // customUrls: [''],
}
