export default {
  primary: {
    // clipPath: 'polygon(10% 0,100% 0%,90% 100%,0% 100%)',
    // clipPath: 'polygon(0 0, 100% 0%, 93% 100%, 0% 100%);',
    borderTop: 'none',
    borderBottom: 'none',
    borderRight: 'none',
    borderLeft: 'double 6px white',
    backgroundColor: 'primary',
    borderRadius: '0px',
    // borderTop: 'double 6px white',
    // borderBottom: 'double 6px white',
    transform: 'skewX(-10deg)',
    color: 'light',
    '&:hover': {
      backgroundColor: 'secondary',
      borderColor: 'primary'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
